<template>
    <div style="background: #f2f2f2;">
        <div class="top">
            <div id="container" v-loading="loading"></div>
        </div>
        <div class="screenWidth">
            <div class="connection_box">
                <div class="left">
                    <div class="title title1">INFORMATION</div>
                    <div class="lianxi" style="align-items: flex-start;">
                        <div>ADDRESS</div>
                        <span>{{ xinxi.address }}</span>
                    </div>
                    <div class="lianxi">
                        <div>TEL</div>
                        <span>{{ xinxi.tel }}</span>
                    </div>
                    <div class="lianxi">
                        <div>mailbox</div>
                        <span>{{ xinxi.email }}</span>
                    </div>
                </div>
                <div class="right">
                    <div class="title">Contact Us and Get Free Samples</div>
                    <div class="one">
                        <div class="jiaodian">
                            <input v-model="name" class="input_box" type="text" placeholder="Name">
                        </div>
                        <div class="jiaodian">
                            <input @input="handleInput(1)" v-model="email" @blur="checkInput('email')"
                                :class="{ 'input_box': true, 'active': inputshowone }" type="text" placeholder="*Email">
                            <div v-if="inputshowone" class="Tips">This field is required</div>
                        </div>
                        <div class="jiaodian">
                            <input @input="handleInput(2)" v-model="tel" @blur="checkInput('tel')"
                                :class="{ 'input_box': true, 'active': inputshowtow }" type="text"
                                placeholder="*Tel/Whatsapp">
                            <div v-if="inputshowtow" class="Tips">This field is required</div>
                        </div>
                    </div>
                    <textarea @input="handleInput(3)" v-model="Description" @blur="checkInput('Description')"
                        :class="{ 'input_box': true, 'active': inputshowthree }" placeholder="*Message"></textarea>
                    <div v-if="inputshowthree" class="Tips">This field is required</div>

                    <button @click="settijiao">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            sibItem: [
                {
                    title: "Home",
                    path: "/",
                },
                {
                    title: "contactUs",
                    path: "",
                },
            ],
            inputshowone: false,
            inputshowtow: false,
            inputshowthree: false,

            name: '',
            email: '',
            tel: '',
            Description: '',
            loading: true,
            map: null,
            infoWindow: null, //信息窗口
            positionData: [],
            xinxi: '',
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.getlianxi()
        })
    },
    created() {
        //标注文字上的点击事件
        window.handleDetail = (deviceMarkId) => {
            this.$router.push({ path: "/device/nowMonitor", query: { deviceMarkId } })
        }
    },
    methods: {
        getlianxi() {
            this.$request({
                url: "/Index/telUs",
            }).then((res) => {
                this.xinxi = res.data
                this.$nextTick(() => {
                    this.initMap(res.data)
                })
            }).catch(err => {
                this.loading = false
            })
        },
        initMap(ress) {
            this.map = new AMap.Map("container", {  //设置地图容器id
                viewMode: "3D",    //是否为3D地图模式
                zoom: 9,           //初始化地图级别
                center: [this.xinxi.address_info.lon, this.xinxi.address_info.lat], //初始化地图中心点位置
                lang: "en", // 地图语言（en英文）
                expandZoomRange: true, //支持可以扩展最大缩放级别
                buildingAnimation: true,//楼块出现是否带动画
                mapStyle: 'amap://styles/normal', //地图样式，彩色
            });
            this.markPoints(ress)
            this.latitude()
        },
        //遍历显示标记点
        markPoints(res) {
            console.log(res, '值');
            const marker = new AMap.Marker({
                position: new AMap.LngLat(res.address_info
                    .lon, res.address_info.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[lng, lat]
            });
            // 将创建的点标记添加到已有的地图实例：
            this.map.add(marker);
            this.loading = false

            //给标记点添加事件
            // marker.on('click', (e) => {
            //     this.setInfoWindows(e, res.address_info,res.address
            //     )
            // })
            let content = [
                `<div style="color:#222;font-weight:bold;">Hebei Jiahong</div><div style="color:#000;">${res.address}</div>`,
            ];
            // 创建 infoWindow 实例	
            let infoWindow = new AMap.InfoWindow({
                content: content.join("<br>")  //传入 dom 对象，或者 html 字符串
            });
            // 打开信息窗体
            let dd = this.map.getCenter()
            // dd.pos = [e.pos[0], e.pos[1]]
            dd.lat = res.address_info.lat
            dd.lng = res.address_info.lon
            console.log(dd)
            infoWindow.open(this.map, dd);

        },
        //显示的弹出框组件
        setInfoWindows(e, item, address
        ) {
            let content = [
                // `<div> <b>兴诺科技</b><br>  地址：${item.address}</div>`,
                `<div>${address}</div>`,
            ];
            // 创建 infoWindow 实例	
            let infoWindow = new AMap.InfoWindow({
                content: content.join("<br>")  //传入 dom 对象，或者 html 字符串
            });
            // 打开信息窗体
            let dd = this.map.getCenter()
            // dd.pos = [e.pos[0], e.pos[1]]
            dd.lat = item.lat
            dd.lng = item.lon
            console.log(dd)
            infoWindow.open(this.map, dd);
        },
        //点击获取经纬度
        latitude() {
            this.map.on('click', function (ev) {
                // console.log(ev)
                // 触发事件的对象
                let target = ev.target;
                // 触发事件的地理坐标，AMap.LngLat 类型
                let lnglat = ev.lnglat;
                // 触发事件的像素坐标，AMap.Pixel 类型
                let pixel = ev.pixel;
                // 触发事件类型
                let type = ev.type;
                console.log(target, lnglat, pixel, type)
            });
        },

        /**
        * 提交联系我们
        * 刘义博
        * 2024-7-20
        */
        settijiao() {
            console.log('123');
            if (this.email == "") {
                this.$message({
                    message: 'Please fill in yo r email address',
                    type: 'warning',
                    offset: 100
                });
                return
            } else if (this.tel == "") {
                this.$message({
                    message: 'Please fill in your phone number or Whatsapp account',
                    type: 'warning',
                    offset: 100
                });
                return
            } else if (this.Description == "") {
                this.$message({
                    message: 'Please leave us a message to understand your needs',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            this.$request({
                url: "/Index/feedBack",
                data: {
                    name: this.name,
                    mobile: this.tel,
                    email: this.email,
                    message: this.Description,
                }
            }).then((res) => {
                console.log("提交成功", res);
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        offset: 100
                    });
                    this.name = ""
                    this.email = ""
                    this.tel = ""
                    this.Description = ""
                }
            }).catch(err => {
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    offset: 100
                });
            })
        },
        handleInput(index) {
            if (index == 1) {
                this.inputshowone = false
            } else if (index == 2) {
                this.inputshowtow = false
            } else if (index == 3) {
                this.inputshowthree = false
            }
        },
        checkInput(field) {
            if (field === 'email' && this.email == '') {
                this.inputshowone = true // 显示提示信息如果email为空
            } else if (field === 'tel' && this.tel == "") {
                this.inputshowtow = true; // 显示提示信息如果tel为空
            } else if (field === 'Description' && this.Description == '') {
                this.inputshowthree = true
            }
        }
    },
    watch: {},
    computed: {},
    filters: {},
    components: {},
    beforeDestroy() {
        if (this.map) {
            this.map.destroy();
        }
    },
}
</script>
<style scoped lang="less">
@media (min-width: 1px) and (max-width: 767px) {
    #container {
        ::v-deep .amap-info-content {
            max-width: 800px !important;

            div:nth-child(1) {
                font-size: 70px !important;
            }

            div {
                text-align: center;
                color: #888;
                padding: 10px;
                font-size: 55px !important;
            }
        }
    }

    #container {
        width: 100% !important;
        height: 1400px !important;
    }

    .one {
        display: flex;
        flex-direction: column;

        .jiaodian {
            width: 100% !important;
        }
    }

    .title1 {
        padding-left: 0 !important;
    }

    .title {
        font-size: 80px !important;
        margin-bottom: 80px !important;
    }

    .connection_box {
        display: flex;
        flex-direction: column;
    }


    .left {
        width: 100% !important;
        margin-bottom: 100px;
        border-top: 20px solid #BA0003 !important;
        padding: 100px 80px !important;


        .lianxi {
            display: flex;
            align-items: center;
            margin-bottom: 30px !important;

            // img {
            //     width: 80px !important;
            //     height: 80px !important;
            // }
            div {
                font-size: 62px !important;
                margin-right: 100px !important;
            }

            span {
                font-size: 60px !important;
            }
        }
    }

    .right {
        width: 100% !important;
        border-top: 20px solid #BA0003 !important;
        padding: 100px 80px !important;

        .input_box {
            padding: 80px 30px !important;
            font-size: 60px !important;
            margin-bottom: 50px !important;
        }

        .Tips {
            font-size: 60px !important;
            margin-top: 0px;
        }

        textarea {
            min-height: 300px !important;
            font-size: 0.7em !important;
            margin-top: 60px !important;
        }

        button {
            padding: 45px 270px !important;
            font-size: 70px !important;
            margin-top: 60px !important;
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    #container {
        ::v-deep .amap-info-content {
            max-width: 800px !important;

            div:nth-child(1) {
                font-size: 50px !important;
            }

            div {
                text-align: center;
                color: #888;
                padding: 10px;
                font-size: 35px !important;
            }
        }
    }

    #container {
        width: 100% !important;
        height: 800px !important;
    }

    .one {
        display: flex;
        flex-direction: column;

        .jiaodian {
            width: 100% !important;
        }
    }

    .title1 {
        padding-left: 0 !important;
    }

    .title {
        font-size: 55px !important;
        margin-bottom: 80px !important;
    }

    .connection_box {
        display: flex;
        flex-direction: column;
    }


    .left {
        width: 100% !important;
        margin-bottom: 100px;
        border-top: 15px solid #BA0003 !important;
        padding: 100px 80px !important;


        .lianxi {
            display: flex;
            align-items: center;
            margin-bottom: 30px !important;

            // img {
            //     width: 80px !important;
            //     height: 80px !important;
            // }
            div {
                font-size: 40px !important;
            }

            span {
                font-size: 35px !important;
            }
        }
    }

    .right {
        width: 100% !important;
        border-top: 15px solid #BA0003 !important;
        padding: 100px 80px !important;

        .input_box {
            padding: 30px 30px !important;
            font-size: 35px !important;
            margin-bottom: 40px !important;
        }

        .Tips {
            font-size: 35px !important;
            margin-top: 0px;
        }

        textarea {
            min-height: 300px !important;
            font-size: 0.7em !important;
            margin-top: 30px !important;
        }

        button {
            padding: 40px 170px !important;
            font-size: 40px !important;
            margin-top: 60px !important;
        }
    }
}

.top {
    width: 100%;
}

#container {
    height: 330px;

    ::v-deep .amap-info-content {
        border: 1px solid #888 !important;
        border-radius: 8px;
        max-width: 250px;

        .infobox-close {
            display: none !important;
        }

        .amap-info-close {
            display: none !important;
        }

        div:nth-child(1) {
            font-size: 16px;
        }

        div {
            text-align: center;
            color: #888;
            padding: 10px;
            font-size: 14px;
        }

        .amap-info-close {
            color: #222;
        }
    }
}

.screenWidth {
    padding: 50px 0;

    .connection_box {
        display: flex;
        justify-content: space-between;

        .title {
            color: #BA0003;
            font-size: 23px;
            margin-bottom: 10px;
        }

        .title1 {
            color: #BA0003;
            margin-bottom: 60px;
        }

        .left {
            width: 40%;
            margin-right: 5%;
            background: #FFFFFF;
            border-top: 7px solid #BA0003;
            padding: 30px 20px;
            box-sizing: border-box;


            .lianxi:last-child {
                border: 0;
            }

            .lianxi {
                padding: 5px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                div {
                    color: #BA0003;
                    margin-right: 15px;
                    white-space: nowrap;
                    font-size: 15px;
                    width: 20% !important;
                }

                span {
                    display: block;
                    width: 80%;
                    color: #222;
                    font-size: 14px;
                    word-break: break-word !important;
                }

                .active {
                    color: #222;
                    cursor: pointer;
                }

                .active:hover {
                    color: #BA0003;
                }
            }
        }

        .right {
            width: 60%;
            background: #FFFFFF;
            border-top: 7px solid #BA0003;
            padding: 30px 20px;
            box-sizing: border-box;

            .one {
                display: flex;
                justify-content: space-between;

                .jiaodian {
                    width: 30%;
                }
            }

            .input_box {
                box-sizing: border-box !important;
                color: rgb(0, 0, 0);
                width: 100%;
                padding: 10px 0;
                text-indent: 6px;
                box-sizing: content-box;
                border-radius: 2px;
                border: 1px solid #ccc !important;
                box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1) inset;
                margin-bottom: 10px;
                font-size: 20px;
                font: -webkit-mini-control;
                background: #F4F3EF;
            }

            .input_box:focus {
                outline: none;
            }

            textarea {
                min-height: 100px;
                font-size: 24px;
                font-weight: bold;
                resize: vertical;
                margin-top: 20px;
            }

            .Tips {
                width: 100%;
                font-size: 14px;
                color: red;
                margin-top: 0px;
            }

            button {
                display: flex;
                background: #BA0003;
                color: #fff;
                border: 0;
                padding: 10px 70px;
                font: -webkit-mini-control;
                margin-top: 10px;
                cursor: pointer;
            }

            .active {
                background-color: rgba(255, 0, 0, 0.1) !important;
            }
        }
    }
}
</style>